import { ThunkAction } from "redux-thunk";

import { RootState } from "../../../../store/store";
import { User } from "../../../@types/user.type";
import { http } from "../../../http";
import { API_ENDPOINTS } from "../../endpoints.api";
import { AuthLoginPost } from "../types";
import { LogoutAction } from "./types";

export const LOGIN_REQUEST = "LOGIN.REQUEST";
export const LOGIN_SUCCESS = "LOGIN.SUCCESS";
export const LOGIN_FAILURE = "LOGIN.FAILURE";
export const LOGOUT = "LOGOUT";

export type AuthAction =
  | { type: typeof LOGIN_REQUEST }
  | { type: typeof LOGIN_SUCCESS; payload: User }
  | { type: typeof LOGIN_FAILURE; payload: string }
  | { type: typeof LOGOUT };

export const login =
  (
    payload: AuthLoginPost,
    setIsLoading?: (isLoading: boolean) => void,
  ): ThunkAction<void, RootState, unknown, AuthAction> =>
  async (dispatch) => {
    dispatch({ type: LOGIN_REQUEST });
    setIsLoading?.(true);
    try {
      const response: any = await http.post<AuthLoginPost>(
        API_ENDPOINTS.AUTH.LOGIN,
        payload,
      );
      setIsLoading?.(false);
      if (response.data) {
        const { token, refreshToken, user } = response.data;
        localStorage.setItem("token", token);
        localStorage.setItem("refreshToken", refreshToken);
        dispatch({ type: LOGIN_SUCCESS, payload: user });
      }
    } catch (error: any) {
      dispatch({ type: LOGIN_FAILURE, payload: error.message });
    } finally {
      setIsLoading?.(false);
    }
  };

export const logout = (): LogoutAction => {
  return {
    type: LOGOUT,
  };
};

export const me =
  (payload: User): ThunkAction<void, RootState, unknown, AuthAction> =>
  async (dispatch) => {
    dispatch({ type: LOGIN_REQUEST });
    try {
      const response = await http.post<User>(API_ENDPOINTS.AUTH.ME, payload);
      // dispatch({ type: LOGIN_SUCCESS, payload: response.data });
    } catch (error: any) {
      dispatch({ type: LOGIN_FAILURE, payload: error.message });
    }
  };
