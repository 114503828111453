import moment from "moment/moment";

export enum TimeNamedFormat {
  DATE_TIME_SIMPLE = "MMM DD, YYYY",
  DATE_TIME_HOURS = "MMM DD, YYYY HH:mm",
  DATE_TIME_AM_PM = "DD MMM YYYY, hh:mm A",
  DATE_TIME_SECONDS = "DD.MM.YYYY HH:mm:ss",
  DAY_OF_MONTH = "D",
  DAY_OF_MONTH_SUFFIX = "Do",
  DAY_OF_WEEK = "E",
  DAY_OF_WEEK_LOCAL = "e",
  DAY_OF_YEAR = "DDD",
  ERA = "GGGG",
  ERA_NAME = "GG",
  EXTENDED_YEAR = "ggggg",
  EXTENDED_YEAR_ISO = "GGGGG",
  HOUR = "HH",
  HOUR_AM_PM = "hh A",
  HOUR_MINUTE = "HH:mm",
  HOUR_MINUTE_AM_PM = "hh:mm A",
  HOUR_MINUTE_MILLISECOND = "HH:mm.SSS",
  HOUR_MINUTE_MILLISECOND_AM_PM = "hh:mm.SSS A",
  HOUR_MINUTE_SECOND = "HH:mm:ss",
  HOUR_MINUTE_SECOND_AM_PM = "hh:mm:ss A",
  HOUR_MINUTE_SECOND_MILLISECOND = "HH:mm:ss.SSS",
  HOUR_MINUTE_SECOND_MILLISECOND_AM_PM = "hh:mm:ss.SSS A",
  HOUR_MINUTE_SECOND_TIMEZONE = "HH:mm:ss ZZ",
  HOUR_MINUTE_SECOND_TIMEZONE_AM_PM = "hh:mm:ss ZZ",
  HOUR_MINUTE_TIMEZONE = "HH:mm ZZ",
  HOUR_MINUTE_TIMEZONE_AM_PM = "hh:mm ZZ",
  MILLISECOND = "SSS",
  MINUTE = "mm",
  MINUTE_SECOND = "mm:ss",
  MINUTE_SECOND_MILLISECOND = "mm:ss.SSS",
  MONTH = "MMMM",
  MONTH_DAY = "MMMM D",
  MONTH_DAY_MIN = "MM D",
  MONTH_DAY_SHORT = "MMM D",
  MONTH_MIN = "MM",
  MONTH_SHORT = "MMM",
  MONTH_WEEKDAY = "MMMM, dddd",
  MONTH_WEEKDAY_DAY = "MMMM D, dddd",
  MONTH_WEEKDAY_DAY_MIN = "MM D, dd",
  MONTH_WEEKDAY_DAY_SHORT = "MMM D, ddd",
  MONTH_WEEKDAY_DAY_YEAR = "MMMM D, dddd YYYY",
  MONTH_WEEKDAY_DAY_YEAR_MIN = "MM D, dd YYYY",
  MONTH_WEEKDAY_DAY_YEAR_SHORT = "MMM D, ddd YYYY",
  MONTH_WEEKDAY_MIN = "MM, dd",
  MONTH_WEEKDAY_SHORT = "MMM, ddd",
  QUARTER = "Q",
  QUARTER_STANDALONE = "q",
  SECOND = "ss",
  SECOND_MILLISECOND = "ss.SSS",
  TIMEZONE = "ZZ",
  TIMEZONE_NAME = "zz",
  TIMEZONE_OFFSET_SHORT = "Z",
  TIMEZONE_OFFSET_WITH_COLON = "ZZZ",
  UNIX_TIMESTAMP_MILLISECONDS = "x",
  UNIX_TIMESTAMP_SECONDS = "X",
  WEEK_OF_MONTH = "W",
  WEEK_OF_WEEK_BASED_YEAR = "ww",
  WEEK_OF_WEEK_BASED_YEAR_ISO = "WW",
  WEEK_OF_YEAR = "w",
  WEEKDAY = "dddd",
  WEEKDAY_MIN = "dd",
  WEEKDAY_SHORT = "ddd",
  YEAR = "YYYY",
  YEAR_SHORT = "YY",
  YEAR_WEEK = "gggg",
  DATE = "DD.MM.YYYY",
  DEFAULT = "ddd mmm dd yyyy HH:MM:ss",
  SHORT_DATE = "m/d/yy",
  PADDED_SHORT_DATE = "mm/dd/yyyy",
  MEDIUM_DATE = "mmm d, yyyy",
  LONG_DATE = "mmmm d, yyyy",
  FULL_DATE = "dddd, mmmm d, yyyy",
  SHORT_TIME = "h:MM TT",
  MEDIUM_TIME = "h:MM:ss TT",
  LONG_TIME = "h:MM:ss TT Z",
  ISO_DATE = "yyyy-mm-dd",
  ISO_TIME = "HH:MM:ss",
  ISO_DATE_TIME = "yyyy-mm-dd'T'HH:MM:sso",
}

export const safeFormat = (
  date: string | Date | undefined,
  format: TimeNamedFormat,
) => {
  if (!date) return "";
  const momentDate = moment(date);
  return momentDate.isValid() ? momentDate.format(format) : "";
};
