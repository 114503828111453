import { AnyAction, combineReducers } from "@reduxjs/toolkit";
import moment from "moment";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import expireReducer from "redux-persist-expire";

import { stateEntityAuth } from "../middleware/api/auth/stateEntity";
import { LOGOUT } from "../middleware/api/auth/stateEntity/actions";
import { sliceSubscriptions } from "../middleware/api/billing/subscriptions/subscriptions.slice";
import { sliceFilters } from "../middleware/api/offices/filters/filters.slice";
import { sliceSearch } from "../middleware/api/offices/search/search.slice";
import { sliceStatistics } from "../middleware/api/offices/statistics/statistics.slice";

const EXPIRE_TIME_SECONDS = moment.duration(3, "hours").asSeconds();

const persistConfig = {
  key: "root",
  storage,
  version: 1,
  transforms: [
    expireReducer("subscriptions", {
      expireSeconds: EXPIRE_TIME_SECONDS,
      expiredState: sliceSubscriptions.getInitialState(),
      autoExpire: true,
    }),
    expireReducer("statistics", {
      expireSeconds: EXPIRE_TIME_SECONDS,
      expiredState: sliceStatistics.getInitialState(),
      autoExpire: true,
    }),
    expireReducer("search", {
      expireSeconds: EXPIRE_TIME_SECONDS,
      expiredState: sliceSearch.getInitialState(),
      autoExpire: true,
    }),
    expireReducer("filters", {
      expireSeconds: EXPIRE_TIME_SECONDS,
      expiredState: sliceFilters.getInitialState(),
      autoExpire: true,
    }),
  ],
};

const appReducer = combineReducers({
  auth: stateEntityAuth.reducer,
  subscriptions: sliceSubscriptions.reducer,
  statistics: sliceStatistics.reducer,
  search: sliceSearch.reducer,
  filters: sliceFilters.reducer,
});

export type RootState = ReturnType<typeof appReducer>;

const rootReducer = (state: RootState | undefined, action: AnyAction) => {
  if (action.type === "CLEAR_CACHED") {
    if (state?.auth) {
      return appReducer(
        {
          auth: state.auth,
          subscriptions: sliceSubscriptions.reducer(undefined, action),
          statistics: sliceStatistics.reducer(undefined, action),
          search: sliceSearch.reducer(undefined, action),
          filters: sliceFilters.reducer(undefined, action),
        },
        action,
      );
    }
  }

  if (action.type === LOGOUT) {
    storage.removeItem("persist:root").catch();
    localStorage.clear();
    sessionStorage.clear();

    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export const persistedReducer = persistReducer(persistConfig, rootReducer);

export type AppState = ReturnType<typeof persistedReducer>;
